import React, { useState } from 'react'

import Layout from "../../components/layout/layout-st"
import Seo from "../../components/seo"

import * as style from '../../components/content/content.module.scss'

import ReactPlayer from 'react-player/youtube'


const Videos = () => {

  const [video, newVideo] = useState('https://www.youtube.com/watch?v=CFtUZ6_JwOs');

  const renderLoadButton = (id, label) => {
    return (
      <button onClick={() => newVideo('https://www.youtube.com/watch?v=' + id)} className={style.videoButton}>
        <img src={'https://i.ytimg.com/vi/' + id + '/hqdefault.jpg'} alt={label}/>
        <span>{label}</span>
      </button>
    )
  }

  return (

    <Layout>

      <Seo title="Videos" bodyClass='st' />

   

      <div className="column column--100">

        <div className={style.video}>
          <ReactPlayer
            // ref={this.ref}
            className='react-player'
            width='100%'
            height='100%'
            url={video}
            // playing
          />
        </div>
        <h1 className={style.titleLowercase}>Videos</h1>
        <p>There’s always a Story to tell! Check out T’s official videos.</p>
        <div className={style.videoList}>
        
          {renderLoadButton('CFtUZ6_JwOs', 'Double Take')}
          {renderLoadButton('G5O3fv0RmkM', 'Lowkey')}
          {renderLoadButton('S7ZXlTa3v6Y', 'One Thing')}
          {renderLoadButton('8yJ4lqhZxSM', 'See Thru')}
          {renderLoadButton('8D586bZtC9o', 'Conflicted')}
          {renderLoadButton('qooWjfIJbp0', 'Walk Away')}
          {renderLoadButton('PM37LphEbFw', 'Holiday Linen')}
          {renderLoadButton('fS4SAjmv1vM', 'Midnight Shade')}
          {renderLoadButton('tdfGf-Z3FS0', 'Box Food')}
          {renderLoadButton('rRsI-AOsGWU', 'Lately')}
          {renderLoadButton('D282c6JEQtE', 'Remind Me')}
          {renderLoadButton('oqMs21CKi3c', 'Your Place')}
          {renderLoadButton('qmpe_oPFJoY', 'Three')}
          {renderLoadButton('QhtEQFh__xw', 'Yorkshire Pudding')}       
          {renderLoadButton('8Q_84yEXo9s', 'Bedroom Bully')}
          {renderLoadButton('F9adPRjCns4', 'Lip Service')}
          {renderLoadButton('4Q_eM-nKrwA', 'Sunday Siesta')}
          {renderLoadButton('8Lfpp4zZgpM', 'Side Effects')}
          {renderLoadButton('x2Fwdyu2mB0', 'Paper Cuts')}
          {renderLoadButton('KsxoK1Dc0mw', 'Almost Lover')}
          {renderLoadButton('9yqMcUTvDD8', 'Paralyzed')}
          {renderLoadButton('_ctYjHH64Rw', 'Airtight')}
          {renderLoadButton('ZjPnaVaruTo', 'Get Me Thru')}
          {renderLoadButton('MBLrhoVafbs', 'Ice Cream Cone')}
          {/* {renderLoadButton('9Zw5XXpBJNI', 'Ice Cream Cone (Parody)')} */}
          {/* {renderLoadButton('er9-NkyG6z4', 'Specyal T on Rogers TV')} */}
        </div>

      </div>


    </Layout>
  )
}
export default Videos